<template>
  <div>
    <div class="block block-rounded block-fx-pop">
      <div class="col d-flex justify-content-end align-items-center">
        <router-link data-cy="new-asset" class="btn btn-primary mt-4 mr-3" :to="{ name: 'automation-create' }"
          ><i class="fa fa-plus fa-fw"></i> New Automation</router-link
        >
      </div>
      <div class="block-content block-content-full">
        <DataTable
          :items="automations"
          :total="automationsTotal"
          :loading="loading || automationsLoading.list"
          :columns="columns"
          :filters="filters"
          :page-size="20"
          :fetch-items="refreshAutomations"
          :other-params="otherParams"
          default-sort-key="uploadedAt"
          :default-sort-direction="-1"
        >
          <template v-slot:lastCollection="{ data }">
            {{ data.lastCollection?.collectedAt | date('Do MMM YYYY HH:mm:ss') }}
          </template>
          <template v-slot:mpan="{ data }">
            <div v-if="data.category === 'accounts'">{{ accounts.find(a => a.automationIds.includes(data._id))?.meterPointNumber }}</div>
          </template>
          <template v-slot:actions="{ data }">
            <div>
              <router-link
                class="btn btn-sm btn-primary"
                :to="{
                  name: 'automation-manage',
                  params: { id: data._id }
                }"
                >Manage</router-link
              >
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { throttle } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import DataTable from '@/components/DataTable';

export default {
  name: 'AutomationList',
  components: {
    DataTable
  },
  data() {
    return {
      loading: true,
      filterType: 'all',
      filters: {
        assetId: null,
        entityId: null
      },
      otherParams: {
        $multi: true,
        $populate: 'assets',
        $select: 'description,service,source,category,active,lastCollection,uploadedAt'
      }
    };
  },
  computed: {
    ...mapGetters({
      automations: 'automation/automations',
      automationsTotal: 'automation/total',
      automationsLoading: 'automation/loadingAction',
      accounts: 'account/accounts',
      automationLoading: 'automation/loadingAction'
    }),
    columns() {
      return [
        { name: 'Description', code: 'description', search: true },
        { name: 'Service', code: 'service', search: true },
        { name: 'Source', code: 'source', search: true },
        { name: 'Category', code: 'category' },
        { name: 'Active', code: 'active', search: true },
        { name: 'Last Collection Date', code: 'lastCollection', search: true },
        { name: 'Actions', code: 'actions' }
      ];
    }
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    ...mapActions({
      listAccounts: 'account/list',
      listAutomation: 'automation/list'
    }),
    refreshAutomations: throttle(async function db(params) {
      const filters = Object.keys(this.filters).reduce((acc, curr) => {
        if (this.filters[curr]) {
          acc[curr] = this.filters[curr];
        }
        return acc;
      }, {});

      const defaultParams = {
        data: {
          params: {
            ...this.otherParams,
            ...filters,
            $limit: 20,
            $skip: 0,
            $sort: 'uploadedAt:-1'
          }
        }
      };

      if (!this.$auth.isAdmin) {
        defaultParams.data.params.category = 'accounts';
      }

      this.loading = true;

      await this.listAutomation(params || defaultParams);

      if (this.automations.length > 0) {
        await this.listAccounts({
          data: {
            params: {
              $multi: true,
              automationIds: this.automations.map(a => a._id).join(','),
              $select: 'meterPointNumber,automationIds'
            }
          }
        });
      }

      this.loading = false;
    }, 500)
  }
};
</script>
